import Api from "@/api/Api";

export default {
  Get() {
    return Api().get("/transaction");
  },
  GetByUser(id) {
    return Api().get(`/transaction/byuser/${id}`);
  },
  GetDetail(id) {
    return Api().get(`/transaction/${id}`);
  },
  GetHistory(id) {
    return Api().get(`/transaction/history/${id}`);
  },
  RequestPickup(id) {
    return Api().post(`/shipping/pickup`, id);
  },
  PrintLabel(id) {
    return Api().post(`/shipping/resi`, id, {
      responseType: "blob",
    });
  },
  GetAddress() {
    return Api().get(`/address`);
  },
  GetAttrCart(data) {
    return Api().post(`/transaction/cartattr`, data);
  },
  SetShipped(data) {
    return Api().post(`/transaction/setshipped`, data);
  },
  SetPaid(data) {
    return Api().post(`/transaction/setpaid`, data);
  },
  SetCancel(data) {
    return Api().post(`/transaction/setcancel`, data);
  },
  SetFinish(data) {
    return Api().post(`/transaction/setfinish`, data);
  },
  DownloadPdf(data) {
    return Api().post("transaction/pdf/create", data);
  },
  //   GetOptionUserRole(role) {
  //     return Api().get(`/user/getuser?role=${role}`);
  //   },
  //   Update(id, data) {
  //     return Api().put(`/user/${id}`, data);
  //   },
  // Add(data) {
  //   return Api().post(`/user/role`, data);
  // },
  //   Delete(id) {
  //     return Api().delete(`/user/${id}`);
  //   },
  // DeleteMultiple(data) {
  //   return Api().post(`/user/role/all`, data);
  // },
  //   UploadImage(id, data) {
  //     return Api().post(`user/upload/${id}`, data);
  //   },
};
