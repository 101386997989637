import Api from "@/api/Api";

export default {
  Get() {
    return Api().get("/products");
  },
  Details(id) {
    return Api().get(`/product/${id}`);
  },
  GetList(id) {
    return Api().get(`/product/attr/list/${id}`);
  },
  //   getDetail(id) {
  //     return Api().get(`/product/${id}`);
  //   },
  //   GetOptionproductRole(role) {
  //     return Api().get(`/product/getproduct?role=${role}`);
  //   },
  Update(id, data) {
    return Api().put(`/product/${id}`, data);
  },
  Add(data) {
    return Api().post(`/product/`, data);
  },
  Delete(id) {
    return Api().delete(`/product/${id}`);
  },
  DeleteMultiple(data) {
    return Api().post(`/product/all`, data);
  },
  UploadImage(id, data) {
    return Api().post(`/product/upload/${id}`, data);
  },
};
