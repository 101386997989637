<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <h5>Management Order</h5>
            </div>
          </template>
        </Toolbar>

        <TabView>
          <TabPanel :header="`All (${menu.length})`">
            <DataTable
              ref="dt"
              :value="menu"
              dataKey="_id"
              :paginator="true"
              :rows="10"
              :filters="filters"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 25]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
              responsiveLayout="scroll"
            >
              <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                  <h5 class="m-0">Order List</h5>
                  <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Search..." />
                  </span>
                </div>
              </template>
              <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
              <Column field="transaction number" header="Order Number" :sortable="true">
                <template #body="slotProps">
                  {{ slotProps.data.number }}
                </template>
              </Column>

              <Column field="status" header=" Status" :sortable="true">
                <template #body="slotProps">
                  {{ `${slotProps.data.status}` == "pending_payment" ? "pending payment" : `${slotProps.data.status}` }}
                </template>
              </Column>
              <Column field="total_items" header="Total Items" :sortable="true">
                <template #body="slotProps">
                  {{ `${slotProps.data.transaction_detail.length} items` }}
                </template>
              </Column>
              <Column field="grand_total" header="grand_total" :sortable="true">
                <template #body="slotProps">
                  {{ formatRupiah(slotProps.data.grand_total, "Rp") }}
                </template>
              </Column>

              <Column headerStyle="min-width:10rem;" header="Actions">
                <template #body="slotProps">
                  <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-warning mr-2"
                    @click="
                      () => {
                        $router.push(`/admin/transaction-management/transaction/${slotProps.data._id}/update`);
                      }
                    "
                  />
                </template>
              </Column>
            </DataTable>
          </TabPanel>
          <TabPanel :header="`pending payment (${pending_payment_data.length})`">
            <DataTable
              ref="dt"
              :value="pending_payment_data"
              dataKey="_id"
              :paginator="true"
              :rows="10"
              :filters="filters"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 25]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
              responsiveLayout="scroll"
            >
              <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                  <h5 class="m-0">Order List</h5>
                  <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Search..." />
                  </span>
                </div>
              </template>
              <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
              <Column field="transaction number" header="Order Number" :sortable="true">
                <template #body="slotProps">
                  {{ slotProps.data.number }}
                </template>
              </Column>

              <Column field="status" header=" Status" :sortable="true">
                <template #body="slotProps">
                  {{ `${slotProps.data.status}` == "pending_payment" ? "pending payment" : `${slotProps.data.status}` }}
                </template>
              </Column>
              <Column field="total items" header="Total Items" :sortable="true">
                <template #body="slotProps">
                  {{ `${slotProps.data.transaction_detail.length} items` }}
                </template>
              </Column>
              <Column field="grand total" header="grand_total" :sortable="true">
                <template #body="slotProps">
                  {{ formatRupiah(slotProps.data.grand_total, "Rp") }}
                </template>
              </Column>

              <Column headerStyle="min-width:10rem;" header="Actions">
                <template #body="slotProps">
                  <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-warning mr-2"
                    @click="
                      () => {
                        $router.push(`/admin/transaction-management/transaction/${slotProps.data._id}/update`);
                      }
                    "
                  />
                </template>
              </Column>
            </DataTable>
          </TabPanel>
          <TabPanel :header="`processed (${process_data.length})`">
            <DataTable
              ref="dt"
              :value="process_data"
              dataKey="_id"
              :paginator="true"
              :rows="10"
              :filters="filters"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 25]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
              responsiveLayout="scroll"
            >
              <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                  <h5 class="m-0">Order List</h5>
                  <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Search..." />
                  </span>
                </div>
              </template>
              <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
              <Column field="transaction number" header="Order Number" :sortable="true">
                <template #body="slotProps">
                  {{ slotProps.data.number }}
                </template>
              </Column>

              <Column field="status" header=" Status" :sortable="true">
                <template #body="slotProps">
                  {{ `${slotProps.data.status}` == "pending_payment" ? "pending payment" : `${slotProps.data.status}` }}
                </template>
              </Column>
              <Column field="total items" header="Total Items" :sortable="true">
                <template #body="slotProps">
                  {{ `${slotProps.data.transaction_detail.length} items` }}
                </template>
              </Column>
              <Column field="grand total" header="grand_total" :sortable="true">
                <template #body="slotProps">
                  {{ formatRupiah(slotProps.data.grand_total, "Rp") }}
                </template>
              </Column>

              <Column headerStyle="min-width:10rem;" header="Actions">
                <template #body="slotProps">
                  <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-warning mr-2"
                    @click="
                      () => {
                        $router.push(`/admin/transaction-management/transaction/${slotProps.data._id}/update`);
                      }
                    "
                  />
                </template>
              </Column>
            </DataTable>
          </TabPanel>
          <TabPanel :header="`shipped (${shipped_data.length})`">
            <DataTable
              ref="dt"
              :value="shipped_data"
              dataKey="_id"
              :paginator="true"
              :rows="10"
              :filters="filters"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 25]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
              responsiveLayout="scroll"
            >
              <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                  <h5 class="m-0">Order List</h5>
                  <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Search..." />
                  </span>
                </div>
              </template>
              <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
              <Column field="transaction number" header="Order Number" :sortable="true">
                <template #body="slotProps">
                  {{ slotProps.data.number }}
                </template>
              </Column>

              <Column field="status" header=" Status" :sortable="true">
                <template #body="slotProps">
                  {{ `${slotProps.data.status}` == "pending_payment" ? "pending payment" : `${slotProps.data.status}` }}
                </template>
              </Column>
              <Column field="total items" header="Total Items" :sortable="true">
                <template #body="slotProps">
                  {{ `${slotProps.data.transaction_detail.length} items` }}
                </template>
              </Column>
              <Column field="grand total" header="grand_total" :sortable="true">
                <template #body="slotProps">
                  {{ formatRupiah(slotProps.data.grand_total, "Rp") }}
                </template>
              </Column>

              <Column headerStyle="min-width:10rem;" header="Actions">
                <template #body="slotProps">
                  <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-warning mr-2"
                    @click="
                      () => {
                        $router.push(`/admin/transaction-management/transaction/${slotProps.data._id}/update`);
                      }
                    "
                  />
                </template>
              </Column>
            </DataTable>
          </TabPanel>
          <TabPanel :header="`finish (${finish_data.length})`">
            <DataTable
              ref="dt"
              :value="finish_data"
              dataKey="_id"
              :paginator="true"
              :rows="10"
              :filters="filters"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 25]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
              responsiveLayout="scroll"
            >
              <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                  <h5 class="m-0">Order List</h5>
                  <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Search..." />
                  </span>
                </div>
              </template>
              <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
              <Column field="transaction number" header="Order Number" :sortable="true">
                <template #body="slotProps">
                  {{ slotProps.data.number }}
                </template>
              </Column>

              <Column field="status" header=" Status" :sortable="true">
                <template #body="slotProps">
                  {{ `${slotProps.data.status}` == "pending_payment" ? "pending payment" : `${slotProps.data.status}` }}
                </template>
              </Column>
              <Column field="total items" header="Total Items" :sortable="true">
                <template #body="slotProps">
                  {{ `${slotProps.data.transaction_detail.length} items` }}
                </template>
              </Column>
              <Column field="grand total" header="grand_total" :sortable="true">
                <template #body="slotProps">
                  {{ formatRupiah(slotProps.data.grand_total, "Rp") }}
                </template>
              </Column>

              <Column headerStyle="min-width:10rem;" header="Actions">
                <template #body="slotProps">
                  <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-warning mr-2"
                    @click="
                      () => {
                        $router.push(`/admin/transaction-management/transaction/${slotProps.data._id}/update`);
                      }
                    "
                  />
                </template>
              </Column>
            </DataTable>
          </TabPanel>
          <TabPanel :header="`cancelled (${cancelled_data.length})`">
            <DataTable
              ref="dt"
              :value="cancelled_data"
              dataKey="_id"
              :paginator="true"
              :rows="10"
              :filters="filters"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 25]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
              responsiveLayout="scroll"
            >
              <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                  <h5 class="m-0">Order List</h5>
                  <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Search..." />
                  </span>
                </div>
              </template>
              <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
              <Column field="transaction number" header="Order Number" :sortable="true">
                <template #body="slotProps">
                  {{ slotProps.data.number }}
                </template>
              </Column>

              <Column field="status" header=" Status" :sortable="true">
                <template #body="slotProps">
                  {{ `${slotProps.data.status}` == "pending_payment" ? "pending payment" : `${slotProps.data.status}` }}
                </template>
              </Column>
              <Column field="total items" header="Total Items" :sortable="true">
                <template #body="slotProps">
                  {{ `${slotProps.data.transaction_detail.length} items` }}
                </template>
              </Column>
              <Column field="grand total" header="grand_total" :sortable="true">
                <template #body="slotProps">
                  {{ formatRupiah(slotProps.data.grand_total, "Rp") }}
                </template>
              </Column>

              <Column headerStyle="min-width:10rem;" header="Actions">
                <template #body="slotProps">
                  <Button
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-warning mr-2"
                    @click="
                      () => {
                        $router.push(`/admin/transaction-management/transaction/${slotProps.data._id}/update`);
                      }
                    "
                  />
                </template>
              </Column>
            </DataTable>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import TransactionApi from "../../api/Transaction";
import ProductApi from "../../api/Product";
import moment from "moment";

export default {
  data() {
    return {
      moment: moment,
      showModalCreate: false,
      showModalDelete: false,
      showModalDeleteItem: false,
      showModalEdit: false,
      selectedRole: {},
      deleteProductDialog: false,
      menu: [],
      temp_item: {},
      selectedProducts: [],
      isLoading: false,
      pending_payment_data: [],
      process_data: [],
      shipped_data: [],
      cancelled_data: [],
      finish_data: [],

      form: {
        name: "",
        weight: 0,
        pre_order_day: 0,
        description: "",
        is_pre_order: false,
        is_use_variant: false,
        is_active: false,
        is_big_stuff: true,
        categories: [],
        collections: [],
      },
    };
  },
  productService: null,
  created() {
    this.initFilters();
  },
  mounted() {
    this.getProducts();
  },

  methods: {
    async getProducts() {
      try {
        let dataset = await TransactionApi.Get();
        if (dataset.data.status === 200) {
          this.menu = dataset.data.data;

          this.pending_payment_data = dataset.data.data.filter((el) => el.status == "pending_payment");
          this.process_data = dataset.data.data.filter((el) => el.status == "process");
          this.shipped_data = dataset.data.data.filter((el) => el.status == "shipped");
          this.cancelled_data = dataset.data.data.filter((el) => el.status == "cancelled");
          this.finish_data = dataset.data.data.filter((el) => el.status == "finish");
        }
      } catch (error) {
        console.log(error);
      }
    },

    async createItem() {
      this.isLoading = true;
      try {
        const response = await ProductApi.Add({
          ...this.form,
          collections: [],
          categories: [],
          is_use_variant: false,
        });

        if (response.data.status === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "New Menu Created!",
            life: 3000,
          });

          // Reset Form
          this.form = {};

          this.getProducts();
          this.isLoading = false;
          this.showModalCreate = false;
        } else {
          this.form = {};

          this.$toast.add({
            severity: "error",
            summary: "Failed!",
            detail: response.data.message,
            life: 3000,
          });
          this.isLoading = false;
          this.showModalCreate = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async showEditModal(user) {
      try {
        this.form = user;
        this.showModalEdit = true;
      } catch (error) {
        console.log(error);
      }
    },

    async editItem() {
      try {
        this.isLoading = true;
        const response = await ProductApi.Update(this.form._id, this.form);

        if (response.data.status === 200) {
          this.getProducts();
          this.form = {};
          this.isLoading = false;
          this.showModalEdit = false;

          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Menu Updated!",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Update Failed!",
            detail: "Something went wrong",
            life: 3000,
          });
          this.showModalEdit = false;
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async deleteMultipleUser() {
      this.isLoading = true;
      try {
        //   reset selected users
        let payload = {
          id: [],
        };

        for (let i = 0; i < this.selectedProducts.length; i++) {
          payload.id.push(this.selectedProducts[i]._id);
        }

        const response = await ProductApi.DeleteMultiple(payload);
        if (response.data.status === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: response.data.message,
            life: 3000,
          });
          this.getProducts();
          this.isLoading = false;
          this.selectedUsers = [];
          this.showModalDelete = false;
        }
        this.selectedProducts = [];
      } catch (error) {
        console.log(error);
      }
    },
    formatRupiah(angka, prefix) {
      var number_string = angka.replace(/[^,\d]/g, "").toString(),
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      // tambahkan titik jika yang di input sudah menjadi angka ribuan
      if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
    },

    confirmDeleteItem(user) {
      this.showModalDeleteItem = true;
      this.temp_item = user;
    },

    async deleteItem(id) {
      try {
        this.isLoading = true;
        const response = await ProductApi.Delete(id);

        if (response.data.status === 200) {
          this.isLoading = false;
          this.showModalDeleteItem = false;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: `${this.temp_item.username} Deleted!`,
            life: 3000,
          });
          this.getProducts();
          this.temp_item = {};
        }
      } catch (error) {
        console.log(error);
      }
    },

    confirmDeleteSelected() {
      this.showModalDelete = true;
    },

    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";

.center-items-vertical {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
